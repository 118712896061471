import * as React from "react"
import Video from "./components/Video"
import { Helmet } from "react-helmet"
import "./index.css"


export default function IndexPage() {
  return <>
     <Helmet>
     <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      </Helmet>

      
<div class="w3-content" style={{maxWidth:1500}}>

{/*<!-- Header -->*/}
<header class="w3-panel w3-center w3-opacity" style={{padding: "128px 16px"}}>
  <h1 class="w3-xlarge">LES LIGNES DROITES</h1>
  <h1>KARL</h1>
  
  {/*<div class="w3-padding-32">
    <div class="w3-bar w3-border">
      <a href="#" class="w3-bar-item w3-button">Home</a>
      <a href="#" class="w3-bar-item w3-button w3-light-grey">Portfolio</a>
      <a href="#" class="w3-bar-item w3-button">Contact</a>
      <a href="#" class="w3-bar-item w3-button w3-hide-small">Weddings</a>
    </div>
</div>*/}
</header>

{/*<div class="w3-row-padding" style={{marginBottom: "128px"}}>
</div>*/}


{/*<!-- Photo Grid -->*/}
<div class="w3-row-padding" style={{marginBottom: "128px"}}>
  <div class="w3-half">

    <div style={{width: "100%"}} align="center">
      
  <iframe
      style={{border: 0, width: "350px", height: "768px"}}
      src="https://bandcamp.com/EmbeddedPlayer/album=1335594779/size=large/bgcol=ffffff/linkcol=333333/transparent=true/"
      seamless>
        <a href="https://leslignesdroites.bandcamp.com/album/karl">KARL by Les Lignes Droites</a>
  </iframe>
  
  </div>

<div style={{width: "100%", marginTop: 20}} align="center">
  <a href="https://drive.google.com/file/d/1PtEX6jwdtYRc01h9r1dsp6RkzbY-5M41/view?usp=sharing">Biographie (PDF)</a>
  </div>

  <div style={{width: "100%", marginTop: 20}} align="center">
  <a href="https://www.dropbox.com/s/su1mj9y5lw3wu54/Les%20Lignes%20Droites%20-%20Karl%20-%20Revue%20de%20presse.pdf?dl=0">Revue de presse (PDF)</a>
  </div>

  </div>

  <div class="w3-half">
  <Video name="Mickey Mickey" youtubeId="xwmNNDMrLgw"/>
    <Video name="Tous des Karl" youtubeId="VEpx5bgp7sI"/>
    <Video name="Tous des Karl (live PETIT BAIN)" youtubeId="r2QCdEsHeZc"/>
  </div>
</div>

</div>
    </>
}


